import { useEffect, useState } from "react";
import { format } from "date-fns";

import { Background, Content } from "./style.js";

import api from "../../services/api.js";
import Auth from "../../services/auth.js";

const MinhasDoacoes = () => {
  const cpf = Auth.token();
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(`/donation-navigate?cpf=${cpf}`);
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, [cpf]);

  if (data && data.length === 0) {
    return (
      <Background>
        <Content>
          <h1>Meus Agendamentos</h1>
          <p>Voce ainda não realizou nenhuma doação.</p>
        </Content>
      </Background>
    );
  } else {
    return (
      <>
        <Background>
          <Content>
            <h1>Meus Agendamentos</h1>
            <table>
              <thead>
                <tr>
                  <th>Dia da Doação</th>
                  <th>Horário da Doação</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {format(new Date(item.data_doacao), "dd/MM/yyyy")}
                      </td>
                      <td>{item.horario_doacao}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Content>
        </Background>
      </>
    );
  }
};
export default MinhasDoacoes;
