import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import InputMask from "@mona-health/react-input-mask";
import {
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
} from "./styles";
import api from "../../../services/api";
import CPF from "cpf-check";
import Swal from "sweetalert2";
import "./styles.css";

export default function ModalRegister({ showModal, setShowModal }) {
  const modalRef = useRef();
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [dataNasc, setDataNasc] = useState("");

  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  /**
   * Vai servir para gerar o pdf do relatório
   * @param {Previne o evento padrão da função} e
   */
  async function handleSubmit(e) {
    e.preventDefault();

    if (CPF.validate(cpf) === false) {
      return Swal.fire({
        title: "CPF invalido!",
        text: "O cpf não foi reconhecido",
        icon: "warning",
        confirmButtonColor: "var(--red-color)",
      });
    }
  }

  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) =>
            modalRef.current === e.target ? setShowModal(!showModal) : null
          }
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handleSubmit}>
                <h3>Recuperação de Senha</h3>
                <p>Forneça CPF, E-mail e Data de nascimento</p>
                <FormContent>
                  <InputMask
                    type="text"
                    mask="999.999.999-99"
                    value={cpf}
                    pattern="([0-9]{3}\s?\.?\s?[0-9]{3}\s?\.?\s?[0-9]{3}\s?-?\s?[0-9]{2})"
                    onChange={(e) => setCpf(e.target.value)}
                    className="maskSenhaRecuperacao"
                    style={{ textAlign: "center" }}
                    placeholder="Insira seu CPF"
                    required
                  />
                  <Input
                    type="email"
                    placeholder="exemplo@gmail.com"
                    required
                    style={{ textAlign: "center" }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Input
                    type="date"
                    mask="99/99/9999"
                    required
                    placeholder="dd/mm/aaaa"
                    style={{ textAlign: "center" }}
                    pattern="(?:((?:0[1-9]|1[0-9]|2[0-9])\/(?:0[1-9]|1[0-2])|(?:30)\/(?!02)(?:0[1-9]|1[0-2])|31\/(?:0[13578]|1[02]))\/(?:19|20)[0-9]{2})"
                    title="Insira uma data válida"
                    min="1953-01-01"
                    max="9999-12-31"
                    onChange={(e) => setDataNasc(e.target.value)}
                  />
                </FormContent>
                <br />
                <Button
                  type="submit"
                  onClick={async () => {
                    const data = {
                      cpf: cpf,
                      email: email,
                      data_nasc: dataNasc,
                    };
                    if (
                      data.cpf.length === 14 &&
                      data.email !== "" &&
                      data.data_nasc.length === 10
                    ) {
                      try {
                        Swal.fire({
                          title: "Aguarde um momento",
                          icon: "info",
                          confirmButtonColor: "var(--red-color)",
                          didOpen: () => {
                            Swal.showLoading();
                          },
                        });
                        const response = await api.post(`/recover`, { data });
                        if (response.data.error) {
                          return Swal.fire({
                            title: "Atenção!",
                            text: response.data.error,
                            icon: "warning",
                            confirmButtonColor: "var(--red-color)",
                            didOpen: () => {
                              Swal.hideLoading();
                            },
                          });
                        }
                        Swal.close();
                        return Swal.fire({
                          text: `Uma nova senha foi enviada em seu e-mail (Cheque seu SPAM)`,
                          icon: "success",
                          confirmButtonColor: "var(--red-color)",
                        });
                      } catch (error) {}
                    }
                  }}
                >
                  Recuperar senha
                </Button>
              </Form>
              <CloseModalButton onClick={() => setShowModal(!showModal)} />
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
