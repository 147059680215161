import React, { useState, useEffect } from "react";
import { Background, Content, Button, Filters, Select, Header } from "./style";
import Swal from "sweetalert2";
import api from "../../services/api";
import "./inputMask.css";
import { useNavigate } from "react-router-dom";
import TableLogs from "../../Components/Tables/Logs";
import ModalPlanilha from "../../Components/Modal/Doadores";
import Pagination from "../../Components/Pagination";
import Switch from "../../Components/Switch";

const Doadores = () => {
  const navigate = useNavigate();
  const [cpf, setCpf] = useState("");
  const [acao, setAcao] = useState("");
  const [logs, setLogs] = useState([]);
  const [showModalPlanilha, setShowModalPlanilha] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selected, setSelected] = useState(0);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(
          `/admin/logs?cpf=${cpf}&acao=${acao}&limit=${limit}`
        );
        setLogs(response.data.logs);
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/");
        }
      }
      setPage(1);
    };

    fetchData();
  }, [acao, cpf, limit]);

  useEffect(() => {
    setTotalPages(Math.ceil(logs.length / 10));
  }, [logs]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (selected === 0) {
      setCpf(search);
      setAcao("");
    } else {
      setAcao(search);
      setCpf("");
    }
  }, [search]);

  return (
    <Background>
      <Content>
        <Header>
          <h1 style={{ fontWeight: "bolder" }}>Registros de Log</h1>
          <Button
            type="button"
            onClick={async () => {
              Swal.fire({
                title: "Criptografar Texto",
                html:
                  '<input id="swal-input1" class="swal2-input">' +
                  '<input id="swal-input2" class="swal2-input" disabled>' +
                  '<div id="copiado" style="color: var(--red-color)"></div>',
                focusConfirm: true,
                showCloseButton: true,
                confirmButtonText: "Criptografar",
                confirmButtonColor: "var(--red-color)",
                preConfirm: async () => {
                  const data = {
                    value: document.getElementById("swal-input1").value,
                  };
                  await api
                    .post(`/admin/encrypt`, { data })
                    .then((response) => {
                      document.getElementById("swal-input2").value =
                        response.data.value;
                      navigator.clipboard.writeText(response.data.value);
                      document.getElementById("copiado").innerHTML =
                        "Texto copiado!";
                      setTimeout(() => {
                        document.getElementById("copiado").innerHTML = "";
                      }, 2000);
                    })
                    .catch(() => {
                      // console.log(error);
                    });
                  return false;
                },
              });
            }}
          >
            Criptografar
          </Button>
          <Button
            onClick={() => {
              setShowModalPlanilha(true);
            }}
          >
            Ler Planilha
          </Button>
        </Header>
        <hr />
        <Filters>
          <Switch
            text1={"CPF"}
            text2={"Ação"}
            placeholder1={"Insira o CPF"}
            placeholder2={"Insira a ação"}
            search={search}
            selected={selected}
            setSelected={setSelected}
            setSearch={setSearch}
            cpf={"0"}
          />
          <Select value={limit} onChange={(e) => setLimit(e.target.value)}>
            <option value={10}>10 logs</option>
            <option value={50}>50 logs</option>
            <option value={100}>100 logs</option>
            <option value={250}>250 logs</option>
            <option value={500}>500 logs</option>
            <option value={-1}>Sem limite</option>
          </Select>
        </Filters>
        <TableLogs logs={logs.slice((page - 1) * 10, page * 10)} />
        <Pagination page={page} setPage={setPage} totalPages={totalPages} />
      </Content>
      <ModalPlanilha
        showModal={showModalPlanilha}
        setShowModal={setShowModalPlanilha}
      />
    </Background>
  );
};

export default Doadores;
