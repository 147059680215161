import React, { useState, useEffect } from "react";
import { Container, AddAgenda } from "./style";
import Table from "../../Components/Tables/Agenda";
import swal from "sweetalert";
import "react-responsive-modal/styles.css";
import ModalView from "../../Components/Modal/Agenda/View";
import ModalEdit from "../../Components/Modal/Agenda/Edit";
import ModalRegister from "../../Components/Modal/Agenda/Register";
import api from "../../services/api";
import { useNavigate } from "react-router";

const Agendas = () => {
  const navigate = useNavigate();
  const [agenda, setAgenda] = useState([]);
  const [agendaSelected, setAgendaSelected] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalRegister, setShowModalRegister] = useState(false);

  const [dataModalEdit, setDataModalEdit] = useState({});

  useEffect(() => {
    api
      .get(`/calendars`)
      .then((response) => {
        setAgenda(response.data.calendarios);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          navigate("/");
        }
      });
  }, [navigate]);

  function handleClicked(id) {
    setShowModal(true);
    setAgendaSelected(id);
  }

  async function handleEdit(data) {
    if (
      !data ||
      (data?.data_inicio <= new Date().toLocaleDateString() &&
        data?.data_termino >= new Date().toLocaleDateString())
    )
      return;
    setDataModalEdit(data);
    setShowModalEdit(true);
  }

  async function handleDelete(id) {
    try {
      const response = await api.delete(
        `admin/calendars/${id}?confirm=${false}`
      );
      if (response.data.error) {
        swal({
          title: "Você tem certeza?",
          text: "A DATA DA DOAÇÃO A SEGUIR POSSUI DOADORES INSCRITOS, DESEJA APAGAR PERMANENTEMENTE ESSES DADOS?",
          icons: "warning",
          buttons: ["Cancelar", "Apagar"],
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            await api.delete(`admin/calendars/${id}?confirm=${true}`);
            setAgenda(agenda.filter((agenda) => agenda.id !== id));
            swal("Data da doação deletada com sucesso!", {
              icon: "success",
            });
          } else {
            swal("Data da doação não deletada!");
          }
        });
      } else {
        swal({
          title: "Você tem certeza?",
          text: "Uma vez deletada, a data da doação terá que ser cadastrada novamente",
          icon: "warning",
          buttons: ["Cancelar", "Apagar"],
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            await api.delete(`admin/calendars/${id}`);
            setAgenda(agenda.filter((agenda) => agenda.id !== id));
            swal("Data da doação deletada com sucesso!", {
              icon: "success",
            });
          } else {
            swal("Data da doação não deletada!");
          }
        });
      }
    } catch (error) {
      alert("Erro do catch: " + error);
    }
  }

  return (
    <Container>
      <AddAgenda
        to="#"
        onClick={() => {
          setShowModalRegister(!showModalRegister);
        }}
      >
        Nova Agenda
      </AddAgenda>

      <Table
        list={["data_doacão", "data_inicio", "data_término"]}
        data={agenda}
        handleClicked={handleClicked}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
      <ModalRegister
        showModal={showModalRegister}
        setShowModal={setShowModalRegister}
      />
      <ModalEdit
        data={dataModalEdit}
        showModal={showModalEdit}
        setShowModal={setShowModalEdit}
      />
      <ModalView
        showModal={showModal}
        setShowModal={setShowModal}
        id={agendaSelected}
      />
    </Container>
  );
};

export default Agendas;
