import React from "react";
import { TableContainer, TableBody, TableHead } from "./styles";
import { useNavigate } from "react-router-dom";

import ImgVisualizar from "../../../Assets/Images/visualizar.png";
import ImgEditar from "../../../Assets/Images/edit-document.png";
import ImgExcluir from "../../../Assets/Images/delete.png";

export default function Table({ data }) {
  const navigate = useNavigate();

  return (
    <TableContainer>
      <TableHead>
        <tr>
          <td>NOME</td>
          <td>CPF</td>
          <td>E-MAIL</td>
          <td>FONE</td>
          <td>CIDADE</td>
          <td>BAIRRO</td>
          <td align="center">ULTIMO AGEND.</td>
          <td>AÇÕES</td>
        </tr>
      </TableHead>
      <TableBody>
        {data.map((d, i) => (
          <tr
            onClick={() => {
              navigate(`/AtualizarCadastro?cpf=${d.cpf}`);
            }}
            key={i}
          >
            <td data-title="Nome">{d.nome}</td>
            <td data-title="CPF">{d.cpf}</td>
            <td data-title="E-mail">{d.email}</td>
            <td data-title="Fone">{d.telefone}</td>
            <td data-title="Logradouro">{d.cidade}</td>
            <td data-title="Numero">{d.bairro}</td>
            <td align="center" data-title="Ultima Doação">
              {d.ultima_doacao ? d.ultima_doacao : "-"}
            </td>
            <td data-title="Ações">
              <img
                width={24}
                height={24}
                alt="Visualizar"
                src={ImgVisualizar}
                onClick={() => {}}
              />
              <img
                width={24}
                height={24}
                alt="Editar"
                src={ImgEditar}
                onClick={() => {}}
              />
              <img
                width={24}
                height={24}
                alt="Excluir"
                src={ImgExcluir}
                onClick={() => {}}
              />
            </td>
          </tr>
        ))}
      </TableBody>
    </TableContainer>
  );
}
