// import jwt from "jsonwebtoken";
import { jwtDecode } from "jwt-decode";

// const SECRET_KEY = process.env.JWT_SECRET_KEY || "doacaosangue@citec";
const getDecodedToken = () => {
  const token = localStorage.getItem("token");
  if (!token) return false;

  try {
    return jwtDecode(token);
  } catch (error) {
    localStorage.clear();
    return false;
  }
};

export const isAuthenticated = () => {
  return !!getDecodedToken();
};

export const isAuthenticatedAdmin = () => {
  const decoded = getDecodedToken();
  return decoded ? decoded.admin : false;
};

export const isAuthenticatedSubAdmin = () => {
  const decoded = getDecodedToken();
  return decoded ? decoded.sub_admin : false;
};

export const userDecoded = () => {
  return getDecodedToken();
};
