// import jwt from "jsonwebtoken";
import { jwtDecode } from "jwt-decode";

// const SECRET_KEY = process.env.JWT_SECRET_KEY || "doacaosangue@citec";

const Auth = {
  getDecodedToken: function () {
    try {
      const token = localStorage.getItem("token");
      if (!token) return false;
      // jwt.verify(token, SECRET_KEY);
      return jwtDecode(token);
    } catch (error) {
      return false;
    }
  },

  // Função para obter o CPF
  token: function () {
    const decoded = this.getDecodedToken();
    return decoded ? decoded.cpf : false;
  },

  // Função para verificar a autenticação
  auth: function () {
    const decoded = this.getDecodedToken();
    return decoded ? decoded.auth : false;
  },

  // Função para obter os dados completos do usuário
  user: function () {
    return this.getDecodedToken();
  },
};

export default Auth;
