import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import GlobalStyle from "./Styles/Global";

// Obtém o elemento root
const rootElement = document.getElementById("root");

// Cria o root com createRoot
const root = ReactDOM.createRoot(rootElement);

// Renderiza o aplicativo
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>
);
