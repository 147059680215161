const CalculaIdade = (dataNascimento) => {
  const dataNasci = new Date(dataNascimento);
  const dataAtual = new Date();
  const milisegundosDia = 1000 * 60 * 60 * 24;
  let result;
  result =
    Math.ceil((dataAtual.getTime() - dataNasci.getTime()) / milisegundosDia) /
    365;
  // console.log('date Converter result', result)
  if (result < 0) {
    return 0;
  }
  return result;
};

export default CalculaIdade;
