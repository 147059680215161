import React from "react";
import { TableContainer, TableBody, TableHead } from "./styles";
import { useNavigate } from "react-router-dom";

export default function Table({ logs }) {
  const navigate = useNavigate();

  return (
    <TableContainer>
      <TableHead>
        <tr>
          <td width={"10%"}>Data</td>
          <td width={"30%"}>Ação</td>
          <td width={"50%"}>Descrição</td>
          <td width={"10%"}>CPF</td>
        </tr>
      </TableHead>
      <TableBody>
        {logs.length
          ? logs.map((log, i) => (
              <tr
                onClick={() => {
                  navigate(`/AtualizarCadastro?cpf=${log.cpf_usuario}`);
                }}
                key={i}
              >
                <td data-title="Data">{log.data_hora}</td>
                <td data-title="Ação">{log.acao}</td>
                <td data-title="Descrição">{log.descricao}</td>
                <td data-title="CPF">{log.cpf_usuario}</td>
              </tr>
            ))
          : null}
      </TableBody>
    </TableContainer>
  );
}
