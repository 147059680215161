import Swal from "sweetalert2";

export default function VerificaCPF(strCPF_novo) {
  const cpf = strCPF_novo.split(".");
  const digitosA = cpf[0];
  const digitosB = cpf[1];
  const b = cpf[2];
  const c = b.split("-");
  const ultimo = c[0];
  const verificador = c[1];
  const CPF = [digitosA, digitosB, ultimo, verificador].join("");

  if (CPF.length > 10) {
    var Soma;
    var Resto;
    Soma = 0;
    if (CPF === "00000000000") {
      // console.log("Insira um CPF válido!");
    } else if (CPF === "___________") {
      // console.log("vazio");
    } else {
      for (var i = 1; i <= 9; i++)
        Soma = Soma + parseInt(CPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if (Resto === 10 || Resto === 11) Resto = 0;
      if (Resto !== parseInt(CPF.substring(9, 10))) {
        Swal.fire("Erro!", "Digite um CPF válido!", "error");
        localStorage.setItem("cpf valido", "Não");
      } else {
        Soma = 0;
        for (var i2 = 1; i2 <= 10; i++)
          Soma = Soma + parseInt(CPF.substring(i2 - 1, i2)) * (12 - i2);
        Resto = (Soma * 10) % 11;

        if (Resto === 10 || Resto === 11) Resto = 0;
        if (Resto !== parseInt(CPF.substring(10, 11))) {
          Swal.fire("Erro!", "Digite um CPF válido!", "error");
          localStorage.setItem("cpf valido", "Não");
        }
      }
    }
  } else {
    localStorage.setItem("cpf valido", "sim");
  }
}
