/* eslint-disable jsx-a11y/anchor-is-valid */
import { Logos, Fiec, Finalizar, Texto, Link } from "./style";
import fiec from "../../Assets/Images/fiec.png";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import api from "../../services/api";
import Auth from "../../services/auth";

const ConfirmDoacao = () => {
  const navigate = useNavigate();
  const [cpf_doador] = useState(Auth.token());
  const [nome, setNome] = useState("");
  const [data, setData] = useState("");
  const [cpf] = useState(Auth.token());
  const [horario] = useState(localStorage.getItem("horario"));

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/users/?cpf=${cpf_doador}`);
        setNome(response.data.doadores[0].nome);
      } catch (error) {
        if (error.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/");
        }
      }
    };

    fetchData();
  }, [cpf_doador]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/calendars?open=true&cpf=${cpf}`);
        setData(response.data.calendarios[0].data_doacao);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [cpf]);

  return (
    <>
      <Logos>
        {/* <Indaituba>
          <img src={indaituba} alt="Erro" />
        </Indaituba> */}
        <Fiec>
          <img src={fiec} alt="Erro" />
        </Fiec>
      </Logos>

      <Texto>
        <p>Agradecemos sua participação {nome.toUpperCase()}.</p>
        <p>
          Seu agendamento não é apenas um gesto - é uma promessa de vida. Sua
          doação pode salvar até quatro pessoas. Sua responsabilidade hoje é um
          presente de esperança amanhã!
        </p>
        <p>
          Em breve as informações do agendamento serão enviadas em seu email
          (Verifique seu SPAM).
        </p>
        <br />

        {/* <p>
          Lembre-se de que é obrigatorio o uso de máscara no local da doação de
          sangue! Então traga a sua!
        </p>
        <br /> */}

        <p>
          Você deve comparecer no endereço abaixo em {data}, às {horario}h.
        </p>

        <p>
          FIEC I - Av. Eng. Fábio Roberto Barnabé, 3405 - Jardim Regina,
          Indaiatuba
        </p>
        {/* <p>
        PREZADO(A) DOADOR(A), EXCEPCIONALMENTE NESTE MÊS,  DIA 29/10/2022 – SÁBADO, O LOCAL PARA  DOAÇÃO DE SANGUE OCORRERÁ NO ESPAÇO BEM VIVER “MÁRIO JACINTO DA SILVA”, 
        À AVENIDA ENGENHEIRO FÁBIO ROBERTO BARNABÉ, 585 – VILA TELLER – GLEBA 2, DEVIDO AO SEGUNDO TURNO DAS ELEIÇÕES/2022.
        </p>

        <p>
        LEMBRE-SE, VENHA DE MÁSCARA!
        </p> */}
      </Texto>

      <Finalizar>
        <div>
          <Link
            to="/"
            onClick={() => {
              localStorage.removeItem("token");
              navigate("/");
              window.location.reload();
            }}
          >
            Sair
          </Link>
        </div>
      </Finalizar>
    </>
  );
};

export default ConfirmDoacao;
