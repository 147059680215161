// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Background,
//   Title,
//   Row,
//   Content,
//   RowHeader,
//   RowContent,
//   Description,
//   Edit,
//   Delete,
//   Expand,
//   InitialMessages,
//   NormalMessages,
//   InvalidMessages,
//   Input,
//   Select,
//   Add,
//   KeywordContainer,
//   Keyword,
//   OptionContainer,
//   Option,
//   Block,
//   Remove,
// } from "./style";
// import api from "../../services/api";
// import { useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import InputLabel from "../../Components/InputLabel";

export default function ChatBot() {
  // const [chatBot, setChatBot] = useState([]);
  // const [navigate] = useState(useNavigate());
  // const [openIndex, setOpenIndex] = useState(-1);
  // const [editIndex, setEditIndex] = useState(-1);
  // const [keyword, setKeyword] = useState("");
  // const [option, setOption] = useState("");
  // const [teste, setTeste] = useState("");
  // useEffect(() => {
  //   api
  //     .get(`/admin/chatbot`)
  //     .then((response) => {
  //       setChatBot(response.data.chatbot);
  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //         if (error.response.status === 401) {
  //           localStorage.removeItem("token");
  //           navigate({
  //             pathname: "/",
  //           });
  //         }
  //       }
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // useEffect(() => {
  //   // console.log(chatBot);
  // }, [chatBot]);
  // // useEffect(() => {
  // //   console.log(teste);
  // // }, [teste]);
  // async function clear() {
  //   setKeyword("");
  //   setOption("");
  // }
  // async function addKeyword(index) {
  //   if (!keyword)
  //     return Swal.fire({
  //       title: "Atenção!",
  //       text: "Preencha o campo de palavras-chave!",
  //       icon: "warning",
  //       confirmButtonColor: "var(--red-color)",
  //     });
  //   if (
  //     chatBot[index].palavras_chave &&
  //     chatBot[index].palavras_chave.includes(",") &&
  //     chatBot[index].palavras_chave.split(",").includes(keyword)
  //   )
  //     return Swal.fire({
  //       title: "Atenção!",
  //       text: "Essa palavra-chave já está sendo usada!",
  //       icon: "warning",
  //       confirmButtonColor: "var(--red-color)",
  //     });
  //   setChatBot([
  //     ...chatBot.slice(0, index),
  //     {
  //       ...chatBot[index],
  //       palavras_chave: chatBot[index].palavras_chave
  //         ? `${chatBot[index].palavras_chave},${keyword}`
  //         : keyword,
  //     },
  //     ...chatBot.slice(index + 1, chatBot.length),
  //   ]);
  //   setKeyword("");
  // }
  // async function deleteKeyword(value, index) {
  //   setChatBot([
  //     ...chatBot.slice(0, index),
  //     {
  //       ...chatBot[index],
  //       palavras_chave: `${chatBot[index].palavras_chave
  //         .replace(value, "")
  //         .substring(0, 1)
  //         .replace(",", "")}${chatBot[index].palavras_chave
  //         .replace(value, "")
  //         .substring(
  //           1,
  //           chatBot[index].palavras_chave.replace(value, "").length - 1
  //         )
  //         .replace(",,", ",")}${chatBot[index].palavras_chave
  //         .replace(value, "")
  //         .substring(
  //           chatBot[index].palavras_chave.replace(value, "").length - 1,
  //           chatBot[index].palavras_chave.replace(value, "").length
  //         )
  //         .replace(",", "")}`,
  //     },
  //     ...chatBot.slice(index + 1, chatBot.length),
  //   ]);
  // }
  // return (
  //   <Background>
  //     <Container>
  //       <input/>
  //       <InputLabel
  //         type="text"
  //         value={teste}
  //         onChange={(e) => setTeste(e.target.value)}
  //         label="Descrição"
  //       />
  //       <Title>Configurações ChatBot</Title>
  //       <hr />
  //       <Content>
  //         {chatBot.map((c, ic) => (
  //           <Row key={ic}>
  //             <RowHeader>
  //               {c.tipo === "inicial" ? (
  //                 <InitialMessages title="Mensagem Inicial" />
  //               ) : c.tipo === "normal" ? (
  //                 <NormalMessages title="Mensagem Normal" />
  //               ) : (
  //                 <InvalidMessages title="Mensagem Inválida" />
  //               )}
  //               <Description>{c.descricao}</Description>
  //               <Edit
  //                 title="Editar Descrição"
  //                 onClick={() => {
  //                   if (openIndex !== ic) {
  //                     setOpenIndex(ic);
  //                   }
  //                   clear();
  //                   setEditIndex(editIndex === ic ? -1 : ic);
  //                 }}
  //               />
  //               <Delete title="Excluir" />
  //               <Expand
  //                 open={openIndex === ic}
  //                 onClick={() => {
  //                   if (openIndex === ic) {
  //                     setOpenIndex(-1);
  //                   } else {
  //                     setOpenIndex(ic);
  //                   }
  //                   setEditIndex(-1);
  //                   clear();
  //                 }}
  //               />
  //             </RowHeader>
  //             <RowContent open={openIndex === ic}>
  //               <Input
  //                 placeholder="Descrição"
  //                 value={c.descricao}
  //                 disabled={editIndex !== ic}
  //               />
  //               <Select value={c.tipo} disabled={editIndex !== ic}>
  //                 <option value="">TIPO</option>
  //                 <option value="NORMAL">NORMAL</option>
  //                 <option value="INICIAL">INICIAL</option>
  //                 <option value="INVALIDO">INVÁLIDO</option>
  //               </Select>
  //               <Input
  //                 placeholder="Mensagem"
  //                 value={c.mensagem}
  //                 disabled={editIndex !== ic}
  //               />
  //               <KeywordContainer>
  //                 <Input
  //                   disabled={editIndex !== ic}
  //                   placeholder="Insira uma palavra-chave"
  //                   value={keyword}
  //                   onChange={(e) =>
  //                     setKeyword(e.target.value.replaceAll(",", ""))
  //                   }
  //                 />
  //                 <Add onClick={() => addKeyword(ic)} />
  //                 <Block>
  //                   {c.palavras_chave && c.palavras_chave.includes(",") ? (
  //                     c.palavras_chave.split(",").map((p, ip) => (
  //                       <Keyword key={ip}>
  //                         {p} <Remove onClick={() => deleteKeyword(p, ic)} />
  //                       </Keyword>
  //                     ))
  //                   ) : (
  //                     <Keyword>
  //                       {c.palavras_chave}
  //                       <Remove
  //                         onClick={() => deleteKeyword(c.palavras_chave, 0)}
  //                       />
  //                     </Keyword>
  //                   )}
  //                 </Block>
  //               </KeywordContainer>
  //               <OptionContainer>
  //                 <Input
  //                   disabled={editIndex !== ic}
  //                   placeholder="Opção"
  //                   value={option}
  //                   onChange={(e) => setOption(e.target.value)}
  //                 />
  //                 <Select disabled={editIndex !== ic}>
  //                   <option value="">Opções de resposta</option>
  //                   {chatBot.map((c, i) => (
  //                     <option key={i}>{c.mensagem}</option>
  //                   ))}
  //                 </Select>
  //                 <Add />
  //                 <Block>
  //                   {c.opcoes &&
  //                     c.opcoes.includes(",") &&
  //                     c.opcoes.split(",").map((o, io) => (
  //                       <Option key={io}>
  //                         <div>{o}</div>
  //                         <div>
  //                           {
  //                             chatBot.filter(
  //                               (cf) =>
  //                                 cf.id ===
  //                                 parseInt(
  //                                   c.id_opcao_resposta.split(",")[io],
  //                                   10
  //                                 )
  //                             )[0].descricao
  //                           }
  //                           <Remove />
  //                         </div>
  //                       </Option>
  //                     ))}
  //                 </Block>
  //               </OptionContainer>
  //             </RowContent>
  //           </Row>
  //         ))}
  //       </Content>
  //     </Container>
  //   </Background>
  // );
}
