import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Register from "./Pages/Register";
import Home from "./Pages/Home";
import HomeClosed from "./Pages/HomeClosed";
import Info from "./Pages/Info";
import Login from "./Pages/Login";
import Agendas from "./Pages/Agendas";
import Contato from "./Pages/Contato";
import ConfirmData from "./Pages/ConfirmData";
import ConfirmDoacao from "./Pages/ConfirmDoacao";
import Doadores from "./Pages/Doadores/index";
import AtualizarCadastro from "./Pages/AtualizarCadastro";
import PdfDoadores from "./Pages/PdfDoadores";
import Admin from "./Pages/Admin";
import ChatBot from "./Pages/ChatBot";
import {
  isAuthenticated,
  isAuthenticatedAdmin,
  isAuthenticatedSubAdmin,
} from "../src/auth";
import api from "./services/api";
import Graficos from "./Pages/Graficos";
import HomeTemporaria from "./Pages/HomeTemporaria";
import Avisos from "./Pages/Avisos";
import MinhasDoacoes from "./Pages/MinhasDoacoes";
import MeuAgendamento from "./Pages/MeuAgendamento";
import MeuGrupoDoadores from "./Pages/MeuGrupoDoadores";
import Cancelamentos from "./Pages/Cancelamentos";
import TodosGruposDoadores from "./Pages/TodosGruposDoadores";
import Sidebar from "./Components/Sidebar";
import Header from "./Components/Header";
import AgendaDoacoes from "./Pages/AgendaDoacoes";
import SendingEmails from "./Pages/SendingEmails";
// import HomeForaDoAr from "./Pages/HomeForaDoAr";

export const USER_ADMINISTRADOR = 1;
export const USER_DOADOR = 2;
export const USER_PUBLIC = 3;
export const USER_SUB_ADMINISTRADOR = 4;

export default function App() {
  const [closed, setClosed] = useState(false);

  useEffect(() => {
    api
      .get(`/calendarscheck`)
      .then((response) => {
        if (response.data.success) {
          setClosed(false);
        } else {
          setClosed(true);
        }
      })
      .catch((error) => {
        setClosed(true);
      });
  }, []);

  /**
   * Rota para qualquer pessoa
   */
  // const PublicRoute = ({ component: Component, ...rest }) => (
  //   <Route
  //     {...rest}
  //     element={
  //       !isAuthenticated() || isAuthenticated() ? (
  //         <Component />
  //       ) : (
  //         <Navigate to="/" />
  //       )
  //     }
  //   />
  // );

  /**
   * Rota para usuários não administradores, porém logados
   */
  // const PrivateRoute = ({ component: Component, ...rest }) => (
  //   <Route
  //     {...rest}
  //     element={isAuthenticated() ? <Component /> : <Navigate to="/" />}
  //   />
  // );

  /**
   * Rota para usuários administradores logados
   */
  // const AdminRoute = ({ component: Component, ...rest }) => (
  //   <Route
  //     {...rest}
  //     element={isAuthenticatedAdmin() ? <Component /> : <Navigate to="/" />}
  //   />
  // );

  // const SubAdminRoute = ({ component: Component, ...rest }) => (
  //   <Route
  //     {...rest}
  //     element={isAuthenticatedSubAdmin() ? <Component /> : <Navigate to="/" />}
  //   />
  // );

  return (
    <BrowserRouter>
      <Header />
      <Sidebar />
      <Routes>
        {/* Rotas públicas */}
        {closed ? (
          <Route
            path="/Home"
            element={
              !isAuthenticated() || isAuthenticated() ? (
                <HomeClosed />
              ) : (
                <Navigate to="/" />
              )
            }
          />
        ) : (
          // <Route  path="/Home" element={<HomeForaDoAr/>} />
          <Route
            path="/Home"
            element={
              !isAuthenticated() || isAuthenticated() ? (
                <Home />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          // <Route  path="/Home" element={<HomeForaDoAr/>} />
        )}
        <Route
          path="/"
          element={
            !isAuthenticated() || isAuthenticated() ? (
              <HomeTemporaria />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/Login"
          element={
            !isAuthenticated() || isAuthenticated() ? (
              <Login />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/Register"
          element={
            !isAuthenticated() || isAuthenticated() ? (
              <Register />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/Contato"
          element={
            !isAuthenticated() || isAuthenticated() ? (
              <Contato />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/Info"
          element={
            !isAuthenticated() || isAuthenticated() ? (
              <Info />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/Ajuda"
          element={
            !isAuthenticated() || isAuthenticated() ? (
              () => {
                window.location.href = "https://doacaosangue.tawk.help";
              }
            ) : (
              <Navigate to="/" />
            )
          }
        />

        {/* Rotas privadas */}
        <Route
          path="/Agendas"
          element={isAuthenticatedAdmin() ? <Agendas /> : <Navigate to="/" />}
        />
        <Route
          path="/AgendaDoacoes"
          element={
            isAuthenticatedAdmin() ? <AgendaDoacoes /> : <Navigate to="/" />
          }
        />
        <Route
          path="/Avisos"
          element={isAuthenticatedAdmin() ? <Avisos /> : <Navigate to="/" />}
        />
        <Route
          path="/Doadores"
          element={
            isAuthenticatedAdmin() || isAuthenticatedSubAdmin() ? (
              <Doadores />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/Cancelamentos"
          element={
            isAuthenticatedAdmin() ? <Cancelamentos /> : <Navigate to="/" />
          }
        />
        <Route
          path="/EnvioEmails"
          element={
            isAuthenticatedAdmin() ? <SendingEmails /> : <Navigate to="/" />
          }
        />
        <Route
          path="/Graficos"
          element={isAuthenticatedAdmin() ? <Graficos /> : <Navigate to="/" />}
        />
        <Route
          path="/AtualizarCadastro"
          element={
            isAuthenticatedAdmin() || isAuthenticatedSubAdmin() ? (
              <AtualizarCadastro />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/TodosGruposDoadores"
          element={
            isAuthenticatedAdmin() ? (
              <TodosGruposDoadores />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        <Route
          path="/PdfDoadores"
          element={
            isAuthenticatedAdmin() ? <PdfDoadores /> : <Navigate to="/" />
          }
        />

        <Route
          path="/Admin@Sangue"
          element={isAuthenticatedAdmin() ? <Admin /> : <Navigate to="/" />}
        />
        <Route
          path="/Admin@Chatbot"
          element={isAuthenticatedAdmin() ? <ChatBot /> : <Navigate to="/" />}
        />

        <Route
          path="/ConfirmData"
          element={isAuthenticated() ? <ConfirmData /> : <Navigate to="/" />}
        />
        <Route
          path="/MeuAgendamento"
          element={isAuthenticated() ? <MeuAgendamento /> : <Navigate to="/" />}
        />
        <Route
          path="/MeuGrupoDoadores"
          element={
            isAuthenticated() ? <MeuGrupoDoadores /> : <Navigate to="/" />
          }
        />
        <Route
          path="/MinhasDoacoes"
          element={isAuthenticated() ? <MinhasDoacoes /> : <Navigate to="/" />}
        />

        <Route
          path="/ConfirmDoacao"
          element={
            !isAuthenticated() || isAuthenticated() ? (
              <ConfirmDoacao />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        {/* Qualquer rota que esteja fora das estipuladas */}
        <Route path="*" element={() => <h1>Página não encontrada</h1>} />
      </Routes>
    </BrowserRouter>
  );
}
