import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Background,
  Page,
  Subpage,
  Images,
  Content,
  Title,
  Table,
  Breaker,
} from "./style";
import Fiec from "../../Assets/Images/fiec.png";
import api from "../../services/api";
import Swal from "sweetalert2";
import PdfButtons from "../../Components/PdfButtons";

export default function PdfgradeTurma() {
  const [horarios, setHorarios] = useState([]);
  const [array, setArray] = useState("");
  const [data_pdf, setData_pdf] = useState("");
  const location = useLocation();
  const [data_doacao, setData_doacao] = useState("");
  const ref = useRef();

  useEffect(() => {
    api
      .get(`/admin/generatepdf/${location.state.id}`)
      .then((response) => {
        if (response.data.error)
          return Swal.fire({
            title: "Atenção!",
            text: response.data.error,
            icon: "warning",
            confirmButtonColor: "var(--blue-color)",
          });
        setHorarios(response.data?.horariosArray);
        setArray(response.data?.array);
        setData_doacao(response?.data.data_doacao);
        setData_pdf(response?.data.dataPDF);
      })
      .catch((error) => {
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar!",
          icon: "warning",
          confirmButtonColor: "var(--blue-color)",
        });
        console.error(error);
      });
  }, [location.state.id]); // Adicionado 'location.state.id' às dependências

  useEffect(() => {
    if (ref.current.clientHeight > 1045) {
      // console.log("QUEBRAR PÁGINA");
    }
  }, [ref, array]);

  return (
    <Background>
      <PdfButtons />
      <Page>
        <Subpage>
          <Breaker ref={ref}>
            <Content>
              {Object.keys(horarios).map((element1, i) => (
                <React.Fragment key={i}>
                  <Images className={i !== 0 ? "hidden" : ""}>
                    <img src={Fiec} alt="Erro" />
                    {/* <img src={Prefeitura} alt="Erro" /> */}
                  </Images>
                  <Title>Doadores de {data_doacao}</Title>
                  <Title style={{ textAlign: "left" }}>
                    <b>{element1}</b>
                  </Title>
                  <Table key={i}>
                    <thead>
                      <tr>
                        <th>Nº</th>
                        <th style={{ textAlign: "left" }}>NOME</th>
                        <th style={{ textAlign: "left" }}>CPF</th>
                        <th>COMP</th>
                      </tr>
                    </thead>
                    <tbody>
                      {horarios[element1]?.map((element2, i2) => (
                        <tr key={i2}>
                          <td>{i2 + 1}</td>
                          <td style={{ textAlign: "left" }}>
                            {element2?.nome}
                          </td>
                          <td style={{ textAlign: "left" }}>{element2?.cpf}</td>
                          <td>[&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {i !== Object.keys(horarios).length - 1 && (
                    <div style={{ pageBreakAfter: "always" }}></div>
                  )}
                  <br />
                </React.Fragment>
              ))}
              {data_pdf}
              <br />
            </Content>
          </Breaker>
        </Subpage>
      </Page>
    </Background>
  );
}
