import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import InputMask from "@mona-health/react-input-mask";
import {
  TextoCheckbox,
  Checkbox,
  Background,
  ModalContent,
  CloseModalButton,
  Form,
  FormContent,
  Button,
  Input,
} from "./styles";
import api from "../../../services/api";
import Swal from "sweetalert2";
import "./styles.css";
import VerificaCPF from "../../../services/VerificacCpf";
import swal from "sweetalert";
import Auth from "../../../services/auth";

export default function ModalRegister({ showModal, setShowModal }) {
  const modalRef = useRef();
  const cpf_usuario = Auth.user()?.cpf;

  const [cpf, setCpf] = useState("");
  const [cpfNovo, setCpfNovo] = useState("");
  const [nome, setNome] = useState("");
  const [dataDoacao, setDataDoacao] = useState("");
  const [horarioDoacao, setHorarioDoacao] = useState("");
  const [idCalendario, setIdCalendario] = useState(0);
  const animation = useSpring({
    config: {
      duration: 150,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });
  const data = {
    cpf: cpf,
    cpfNovo: cpfNovo,
    horario_doacao: horarioDoacao,
    idCalendario: idCalendario,
  };
  const [Alterar, setAlterar] = useState(false);

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) setShowModal(!showModal);
    },
    [setShowModal, showModal]
  );
  // if (cpfNovo.length > 0 && cpfNovo[13] != "_") {
  //   VerificaCPF(cpfNovo);
  // }
  if (cpfNovo.length > 0 && cpfNovo[13] !== "_") {
    VerificaCPF(cpfNovo);
  }
  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchData = async () => {
      if (cpf.indexOf("_") === -1 && cpf !== "") {
        try {
          const response = await api.get(
            `/admin/checkregistration/?cpf=${cpf}`
          );
          if (response.data.error) {
            return Swal.fire({
              title: "Atenção!",
              text: response.data.error,
              icon: "warning",
              confirmButtonColor: "var(--red-color)",
            });
          } else {
            setNome(response.data.inscricao.nome);
          }
          if (response.data.inscricao.data_doacao) {
            setDataDoacao(response.data.inscricao.data_doacao);
            setHorarioDoacao(response.data.inscricao.horario_doacao);
            setIdCalendario(response.data.inscricao.id_calendario);
          } else {
            return Swal.fire({
              title: "Atenção!",
              text: "Doador não inscrito!",
              icon: "warning",
              confirmButtonColor: "var(--red-color)",
            });
          }
        } catch (error) {
          alert(error);
        }
      } else {
        setDataDoacao("");
        setHorarioDoacao("");
        setNome("");
      }
    };

    fetchData();
  }, [cpf, cpfNovo]); // Only run when 'cpf' or 'cpfNovo' changes

  function clear() {
    setCpf("___.___.___-__");
    setDataDoacao("");
    setHorarioDoacao("");
    setNome("");
    setCpfNovo("___.___.___-__");
    setAlterar(false);
    localStorage.removeItem("Alterar");
  }
  /**
   * Vai servir para gerar o pdf do relatório
   * @param {Previne o evento padrão da função} e
   */
  async function handlePrimeiro(e) {
    e.preventDefault();
    Swal.fire({
      title: "Você tem certeza?",
      text: "A inscrição será cancelada permanentemente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((response) => {
      if (response.isConfirmed) {
        api.delete(
          `/admin/registrations?cpf_usuario=${cpf_usuario}&cpf_doador=${cpf}&id_calendario=${idCalendario}&horario_doacao=${horarioDoacao}`,
          { data }
        );
        Swal.fire({
          title: "Sucesso!",
          text: "Inscrição cancelada com sucesso!",
          icon: "success",
          confirmButtonColor: "var(--red-color)",
        }).then(() => {
          clear();
          setShowModal(false);
        });
      }
    });
  }
  async function handleSegundo(p) {
    p.preventDefault();
    api
      .patch(
        `/admin/updateregistrations?cpf_doador=${cpf}&id_calendario=${idCalendario}&horario_doacao=${horarioDoacao}`,
        { data }
      )
      .then((response) => {
        // console.log(response);
        if (!!response) {
          Swal.fire({
            title: "Sucesso!",
            text: "Inscrição Alterada!",
            icon: "success",
            confirmButtonColor: "var(--red-color)",
          }).then(() => {
            clear();
            setShowModal(false);
          });
        }
      })
      .catch((p) => {
        Swal.fire({
          title: "Alerta!",
          text: "Doador já Inscrito nessa Doação! ",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "var(--red-color)",
        });
      });
  }

  return (
    <>
      {showModal ? (
        <Background
          ref={modalRef}
          onClick={(e) => {
            if (modalRef.current === e.target) {
              setShowModal(!showModal);
              clear();
            }
          }}
        >
          <animated.div style={animation}>
            <ModalContent>
              <br />
              <Form onSubmit={handlePrimeiro}>
                <h3>Cancelar Inscrição</h3>
                <FormContent>
                  <InputMask
                    type="text"
                    mask="999.999.999-99"
                    value={cpf}
                    className="maskCPFRecuperacao"
                    onChange={(e) => setCpf(e.target.value)}
                    style={{ textAlign: "center" }}
                    placeholder="Insira o CPF"
                    required
                  />
                  <Input
                    type="name"
                    value={nome}
                    placeholder="Nome"
                    onChange={(e) => setNome(e.target.value)}
                    disabled
                  />
                  <Input
                    type="text"
                    value={dataDoacao}
                    placeholder="Data de Doação"
                    onChange={(e) => setDataDoacao(e.target.value)}
                    disabled
                  />
                  <Input
                    type="text"
                    value={horarioDoacao}
                    placeholder="Horário de Doação"
                    onChange={(e) => setHorarioDoacao(e.target.value)}
                    disabled
                  />
                </FormContent>
                <br />
                <Button
                  onClick={handlePrimeiro}
                  disabled={nome.length === 0 ? true : false}
                >
                  Cancelar
                </Button>
                <CloseModalButton
                  onClick={() => {
                    //setShowModal(!showModal);
                    setShowModal(false);
                  }}
                />
                <br />
                <Checkbox>
                  <label>
                    <br />
                    <input
                      disabled={nome.length === 0 ? true : false}
                      type="checkbox"
                      className="Checkb"
                      checked={localStorage.getItem("Alterar") === "true"}
                      value={localStorage.getItem("Alterar") || Alterar}
                      style={{ textAlign: "center" }}
                      onClick={(p) => {
                        localStorage.setItem("Alterar", !Alterar);
                        setAlterar(!Alterar);
                      }}
                    />
                    <TextoCheckbox>Substituir Doador</TextoCheckbox>
                  </label>
                </Checkbox>
                {Alterar ? (
                  <InputMask
                    type="text"
                    mask="999.999.999-99"
                    value={cpfNovo}
                    onChange={(p) => setCpfNovo(p.target.value)}
                    className="maskCPFRecuperacao"
                    style={{ textAlign: "center" }}
                    placeholder="Insira o Novo CPF"
                    required
                  />
                ) : null}
                <CloseModalButton
                  onClick={() => {
                    //setShowModal(!showModal);
                    setShowModal(false);
                  }}
                />
                {Alterar ? (
                  <Button
                    onClick={handleSegundo}
                    disabled={nome.length === 0 ? true : false}
                  >
                    Alterar
                    <body
                      onClick={() => {
                        VerificaCPF(cpfNovo);
                        if (localStorage.getItem("cpfValido") === "Sim") {
                          localStorage.getItem("Alterar" === "true");
                          localStorage.clear();
                        } else {
                          swal.fire("Não");
                        }
                      }}
                    />
                  </Button>
                ) : null}
                <br />
              </Form>
            </ModalContent>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
}
