import styled from "styled-components";
import InputMask from "@mona-health/react-input-mask";

export const Container = styled.div`
  width: 100%;

  position: relative;
  margin: 0;
`;

export const Input = styled(InputMask)`
  margin: 0;
  width: 100%;
  height: 40px;
  text-align: left;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid gray;
  outline: none;
  font-size: 1em;
`;

export const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding: 10px;
  height: fit-content;
  width: fit-content;
`;
