import styled from "styled-components";

export const SidebarContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isOpenSidebar",
})`
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  position: fixed;
  right: 0;
  top: 0;

  width: ${({ isOpenSidebar }) => (isOpenSidebar ? "300px" : "50px")};
  height: ${({ isOpenSidebar }) => (isOpenSidebar ? "100%" : "50px")};

  background-color: var(--red-color);

  transition: width 0.3s ease;

  z-index: 20;

  > img {
    margin: 0 auto;
  }

  > a {
    margin: 0.3125rem;
    height: 2.5rem;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    gap: 0.625rem;

    font-size: 1.25rem;

    text-align: start;
    text-decoration: none;

    border-radius: 0.5rem;

    color: white;

    &:hover {
      background-color: #991b1b;
    }

    img {
      width: 2rem;
      border-radius: 0.5rem;
    }
  }
`;

export const SidebarHeader = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isOpenSidebar",
})`
  height: 50px;
  padding: 0 5px;

  display: flex;
  align-items: center;
  justify-content: ${({ isOpenSidebar }) =>
    isOpenSidebar ? "space-between" : "center"};

  > div {
    width: 100%;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    > img {
      width: 40px;
      height: 40px;

      margin: 0 auto;
    }

    > h1 {
      width: 100%;

      color: white;
      font-size: 20px;
      font-weight: bold;

      text-align: center;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      margin: 0 auto;
    }
  }
`;

export const ToggleButton = styled.button`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: end;

  border: none;
  background-color: transparent;

  cursor: pointer;
`;

export const SidebarContent = styled.nav`
  height: 100%;
  padding: 0.625rem;

  display: flex;
  flex-direction: column;

  gap: 0.625rem;

  overflow-y: auto;

  > p {
    width: 100%;
    height: 2px;
    margin: 0 auto;
    border-top: 2px solid white;
  }

  > h1 {
    min-width: 14.375rem;

    overflow: hidden;
    white-space: nowrap;
    font-size: 1.25rem;

    text-align: center;
    text-overflow: ellipsis;

    color: white;
    font-weight: bold;
  }

  > a,
  span {
    width: 100%;
    height: 2.5rem;

    padding: 0 0.625rem;

    display: flex;
    align-items: center;

    font-size: 1.125rem;

    text-align: start;
    text-decoration: none;

    border-radius: 0.5rem;

    color: white;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    cursor: pointer;

    &:hover {
      background-color: #991b1b;
    }
  }

  > a.active {
    font-weight: bold;
    background-color: #f87171;
  }
`;

export const ImageContent = styled.img`
  width: 2.1875rem;
  border-radius: 0.5rem;
  &:hover {
    transition: 0.3s;
    background-color: #991b1b;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;
