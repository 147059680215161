import React, { useState } from "react";
import {
  Background,
  Header,
  ContentForm,
  Input,
  Titulo,
  Form,
  FormController,
  Button,
  Link,
  Label,
} from "./style.js";
import Swal from "sweetalert2";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import Cpf from "../../Assets/Images/cpf.png";
import cadeado from "../../Assets/Images/cadeado.png";
import InputMask from "@mona-health/react-input-mask";
import { jwtDecode } from "jwt-decode";
import ModalRec from "../../Components/Modal/Recuperacao";
import "./inputMask.css";

const Login = () => {
  const navigate = useNavigate();
  const [cpf, setCpf] = useState("");
  const [senha, setSenha] = useState("");
  const [showModal, setShowModal] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    if (cpf.includes("_"))
      return Swal.fire({
        title: "Alerta!",
        text: "Insira um CPF válido!",
        icon: "warning",
        confirmButtonColor: "var(--red-color)",
      });

    const data = {
      cpf: cpf,
      senha: senha,
    };

    try {
      const response = await api.post("/login", { data });
      if (response.data.error) {
        Swal.fire({
          title: "Alerta!",
          text: response.data.error,
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "var(--red-color)",
        });
      } else {
        localStorage.setItem("token", response.data.token);
        const decoded = jwtDecode(response.data.token);
        if (decoded.admin) {
          navigate("/Agendas");
          window.location.reload();
        } else {
          navigate("/MeuAgendamento");
          window.location.reload();
        }
      }
    } catch (error) {
      // Swal.fire({
      //   title: "Alerta!",
      //   text: "Serviço indisponível no momento",
      //   icon: "error",
      //   confirmButtonText: "OK",
      // });
    }
  }

  return (
    <>
      <Background>
        <ContentForm>
          <Header>
            <Titulo>Acessar</Titulo>
          </Header>
          <Form onSubmit={handleSubmit}>
            <FormController style={{ display: "flex" }}>
              <Label>
                <img src={Cpf} alt="Erro" />
              </Label>
              <InputMask
                name="cpf"
                type="text"
                mask="999.999.999-99"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                className="Input"
                placeholder="Insira seu CPF"
                required
              />
            </FormController>
            <FormController>
              <Label>
                <img src={cadeado} alt="Erro" />
              </Label>
              <Input
                type="password"
                name="senha"
                autoComplete="on"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                placeholder="Insira sua senha"
                required
              />
            </FormController>
            <Button>
              <button type="submit" name="botao">
                Entrar
              </button>
            </Button>
            <Link to={"/Register"}>Não tenho cadastro</Link>
            <Link
              to="#"
              onClick={() => {
                localStorage.setItem("cpf", cpf);
                setShowModal(!showModal);
              }}
            >
              Esqueci minha senha
            </Link>
          </Form>
        </ContentForm>
      </Background>
      <ModalRec showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default Login;
